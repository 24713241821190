import React, { useState, useEffect, useContext } from "react";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import logo from '../../images/pirmLogo.png';
import css from './style.module.css';
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../Context/AppContext";

const Login = () => {
  const { setApp } = useContext(AppContext);

  const { authState, oktaAuth } = useOktaAuth();
  const [assignedApps, setAssignedApps] = useState('');
  const [error, setError] = useState(null);
  const [spinner, setSpinner] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      if (authState?.isAuthenticated && authState.accessToken) {
        setSpinner(true);
        try {
          const info = await oktaAuth.getUser();
          const Application = await getAssignedApplications(info.sub);
          setApp(Application);
          if (Application.length > 0) {
            navigate('/home');
          } else {
            setAssignedApps("You don't have access to this application. Please contact your Okta Administrator.");
          }
        } catch (error) {
          console.error("Error fetching user info:", error);
          setError("Error fetching user info");
        } finally {
          setSpinner(false); // Ensure spinner is turned off after process
        }
      } else {
        setAssignedApps(null);
      }
    };

    checkAuthentication();
  }, [authState, oktaAuth, navigate]);

  const getAssignedApplications = async (user) => {
    try {
      const response = await axios.post('//pirm-portal.uc.r.appspot.com/react', { userId: user });
      return response.data.Assigned_Applications;
    } catch (error) {
      console.error("Error fetching assigned applications:", error);
      setError("Error fetching assigned applications");
      return []; // Return empty array in case of error
    }
  };

  const login = () => oktaAuth.signInWithRedirect({ originalUri: "/" });

  return (
    <>
      <div className="d-flex justify-content-center align-items-center min-vh-100">
        <div className="border border-2 rounded documents">
          <header className="text-primary py-3 fw-bold px-5 mt-2 border-bottom border-2 rounded-top text-center">
            <img src={logo} alt="Company logo" className={css.logo} />
          </header>
          <main className="py-3 text-center">
            <p className="text-center fw-bold mt-4  mb-2 " style={{ fontWeight: 400, fontSize: "20px" }}>
              Compliance Portal
            </p>
            <button
              onClick={login}
              className="btn text-white my-4 mx-3 rounded-1"
              style={{ backgroundColor: "#2268DE" }}
            >
              Sign In With OKTA
              {spinner && <i className="ms-3 fas fa-spinner fa-pulse"></i>}
            </button>
            {assignedApps && <p className="text-danger fw-bold px-4">{assignedApps}</p>}
            {/* {error && <p className="text-danger fw-bold px-4">{error}</p>}  */}

          </main>
        </div>
      </div>
    </>
  );
};

export default Login;
