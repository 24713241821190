import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import SkeletonTablePolices from '../../LoadingPages/SkeletonTablePolices';
import css from "../style.module.css";

export default function TablePolices({ data = [] }) {
  const [policies, setPolicies] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [toggelValue, setToggelValue] = useState(false);
  const [originalPolicies, setOriginalPolicies] = useState([]);
  const [serachAppear, setSerachAppear] = useState(false);

  useEffect(() => {
    if (data && data.length > 0) {
      setPolicies(data);
    }
  }, [data]);

  const sortPoliciesByDate = () => {
    setOriginalPolicies(policies)
    const sortedPolicies = [...policies].sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate));
    setPolicies(sortedPolicies);
    setSerachAppear(true); // Show the search div
  };
  const resetPolicies = () => {
    if (originalPolicies.length === 0) {
      return 0
    } else {
      setPolicies(originalPolicies);
      setSerachAppear(false); // Hide the search div
      setSearchData("");
    }
  };
  const searchFunc = (e) => {
    setSearchData(e.target.value.toLowerCase());
  }

  const filteredPolicies = policies.filter((policy) =>
    policy.Name.toLowerCase().includes(searchData) ||
    policy.PIRM_Base__Record_Type__c.toLowerCase().includes(searchData) ||
    policy.pirm_addon__Version_Change_Notes__c?.toLowerCase().includes(searchData)
  );
  const filterToggel = () => {
    setToggelValue(!toggelValue);
  }

  return (
    <div className='mt-2'>
      <div className='position-relative d-flex align-items-center '>
        <div className="inputColor d-flex align-items-center rounded-5 px-2 py-2 " >
          <input
            onChange={searchFunc}
            type="text"
            className={`d-flex justify-content-center border-0 bg-transparent text-muted flex-grow-1 px-4 ${css.noBorder}`}
            placeholder="Hinted search text"
            aria-label="Search"
          />
          <button className="border-0 bg-transparent ms-5 me-2">
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
        <div className='search py-auto d-flex justify-content-center align-items-center'>
          <p className='searchText p-0 m-0 holdCursor' onClick={resetPolicies}>All Documents</p>
          {/* <i className='fas fa-x close-icon ps-1'></i> */}
        </div>
        <div className={serachAppear ? 'search py-auto d-flex justify-content-center align-items-center ' : "d-none"}>
          <p className='searchText p-0 m-0 searchText' >Effective Date - All Time</p>
          <i className='fas fa-x close-icon ps-1 holdCursor' onClick={resetPolicies}></i>
        </div>
        <div className='position-absolute end-0   '>
          <svg onClick={filterToggel} width="24" height="24" viewBox="0 0 24 24" className='me-5 holdCursor' fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_6360_2900)">
              <path fillRule="evenodd" clipRule="evenodd" d="M21 1.5H3C2.60218 1.5 2.22064 1.65804 1.93934 1.93934C1.65804 2.22064 1.5 2.60218 1.5 3V21C1.5 21.3978 1.65804 21.7794 1.93934 22.0607C2.22064 22.342 2.60218 22.5 3 22.5H21C21.3978 22.5 21.7794 22.342 22.0607 22.0607C22.342 21.7794 22.5 21.3978 22.5 21V3C22.5 2.60218 22.342 2.22064 22.0607 1.93934C21.7794 1.65804 21.3978 1.5 21 1.5ZM3 0C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3L0 21C0 21.7956 0.316071 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H21C21.7956 24 22.5587 23.6839 23.1213 23.1213C23.6839 22.5587 24 21.7956 24 21V3C24 2.20435 23.6839 1.44129 23.1213 0.87868C22.5587 0.316071 21.7956 0 21 0L3 0Z" fill="black" />
              <path fillRule="evenodd" clipRule="evenodd" d="M9 17.25C9 17.0511 9.07902 16.8603 9.21967 16.7197C9.36032 16.579 9.55109 16.5 9.75 16.5H14.25C14.4489 16.5 14.6397 16.579 14.7803 16.7197C14.921 16.8603 15 17.0511 15 17.25C15 17.4489 14.921 17.6397 14.7803 17.7803C14.6397 17.921 14.4489 18 14.25 18H9.75C9.55109 18 9.36032 17.921 9.21967 17.7803C9.07902 17.6397 9 17.4489 9 17.25ZM6 12.75C6 12.5511 6.07902 12.3603 6.21967 12.2197C6.36032 12.079 6.55109 12 6.75 12H17.25C17.4489 12 17.6397 12.079 17.7803 12.2197C17.921 12.3603 18 12.5511 18 12.75C18 12.9489 17.921 13.1397 17.7803 13.2803C17.6397 13.421 17.4489 13.5 17.25 13.5H6.75C6.55109 13.5 6.36032 13.421 6.21967 13.2803C6.07902 13.1397 6 12.9489 6 12.75ZM3 8.25C3 8.05109 3.07902 7.86032 3.21967 7.71967C3.36032 7.57902 3.55109 7.5 3.75 7.5H20.25C20.4489 7.5 20.6397 7.57902 20.7803 7.71967C20.921 7.86032 21 8.05109 21 8.25C21 8.44891 20.921 8.63968 20.7803 8.78033C20.6397 8.92098 20.4489 9 20.25 9H3.75C3.55109 9 3.36032 8.92098 3.21967 8.78033C3.07902 8.63968 3 8.44891 3 8.25Z" fill="black" />
            </g>
            <defs>
              <clipPath id="clip0_6360_2900">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <div className={`slide-toggle bg-light border border-2 position-absolute rounded-1  ${toggelValue ? 'open ' : 'closed'}`} onClick={sortPoliciesByDate}>
            <p className='holdCursor pSlected my-0 ' style={{ padding: '10px 10px' }}>Effective Date - All Time</p>
          </div>
        </div>
      </div>
      <table className="w-100 table-bordered documents rounded-table mt-2">
        <thead>
          <tr>
            <th scope="col" className="py-1 px-2">#</th>
            <th scope="col" className="py-1 px-2">Document Name</th>
            <th scope="col" className="py-1 px-2">Type</th>
            <th scope="col" className="py-1 px-2">Version</th>
            <th scope="col" className="py-1 px-2">Effective Date</th>
          </tr>
        </thead>
        <tbody>
          {filteredPolicies.map((policy, index) => (
            <tr key={index}>
              <th scope="row" className="py-1 px-2 border">{index + 1}</th>
              <td className="py-1 px-2">
                <Link
                  to={`/details/${policy.Name}/${policy.Id}`}
                  state={{ policy }}
                  className="text-decoration-none text-dark"
                >
                  {policy.Name}
                </Link>
              </td>
              <td className="py-1 px-2">{policy.PIRM_Base__Record_Type__c}</td>
              <td className="py-1 px-2">{`v${policy.pirm_addon__Version_Change_Notes__c ?? ""}`}</td>

              <td className="py-1 px-2">{(() => {
                const date = policy.CreatedDate.split("T")[0];
                const [year, month, day] = date.split("-");
                return `${month}/${day}/${year}`;
              })()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
