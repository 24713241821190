import { createContext, useState } from "react";

export const AppContext = createContext([]);

export default function AppProvider({ children }) {
  const [app, setApp] = useState([]);
  return (
    <AppContext.Provider value={{ app, setApp }}>
      {children}
    </AppContext.Provider>
  );
}
