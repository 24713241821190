const REACT_APP_PORT = 'http://localhost:3000/login/callback';
const REACT_APP_REDIRECT_URL = 'https://policy-portal-4ca94.web.app/login/callback';
const lendistry = 'https://lendistry.pirm.io/login/callback';

// Function to determine the correct redirect URI
const getRedirectUri = () => {
    const currentHost = window.location.host;
    if (currentHost.includes('localhost')) {
        return REACT_APP_PORT;
    } else if (currentHost.includes('policy-portal-4ca94.web.app')) {
        return REACT_APP_REDIRECT_URL;
    } else if (currentHost.includes('lendistry.pirm.io')) {
        return lendistry;
    }
    // Default to one of the URIs if none match (or throw an error)
    return REACT_APP_REDIRECT_URL;
};

export const oktaConfig = {
  clientId: process.env.REACT_APP_CLIENT_ID,
  issuer: `https://${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/default`,
  redirectUri: getRedirectUri(), // Dynamically set the redirect URI
  scopes: ["openid", "profile", "email"],
  pkce: true,
  disableHttpsCheck: true,
};
