import React from 'react'
import pirm from "../../images/pirmLogo.png"
import { NavLink, useNavigate } from 'react-router-dom'
export default function NavBar({user,setUser}) {
    const navigate = useNavigate();

    const handleLogOut = () => {
      localStorage.clear();
      navigate("/");
    };
    
    return (
        <>
            <nav className=' vh-100 text-center position-relative ' style={{ width: "113px" }}>
                <div className='p-2 pt-3'>
                    <img src={pirm} className='' style={{ width: "113px" }} alt="" />
                </div>
                <div className='mt-4 d-flex flex-column align-items-center  '>
                    <i className="fa-solid fa-bars mb-3 mt-2"></i>
                    <div className='d-flex flex-column  align-items-center justify-content-center my-3 d-none'>
                        <NavLink
                            to="/home"
                            className={({ isActive }) =>
                                isActive ? "text-decoration-none active nav-color  d-flex align-items-center justify-content-center pt-1 " : " text-decoration-none  d-flex align-items-center justify-content-center pt-1 "
                            }
                            style={{ padding: "12px, 0px, 16px, 0px" }}
                        >
                            <svg
                                className='mb-1'
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13 19.9995V11.9995C13 11.7342 12.8946 11.4799 12.7071 11.2923C12.5196 11.1048 12.2652 10.9995 12 10.9995H8C7.73478 10.9995 7.48043 11.1048 7.29289 11.2923C7.10536 11.4799 7 11.7342 7 11.9995V19.9995"
                                    stroke="black"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M1 8.99948C0.99993 8.70855 1.06333 8.4211 1.18579 8.1572C1.30824 7.89329 1.4868 7.65928 1.709 7.47148L8.709 1.47248C9.06999 1.16739 9.52736 1 10 1C10.4726 1 10.93 1.16739 11.291 1.47248L18.291 7.47148C18.5132 7.65928 18.6918 7.89329 18.8142 8.1572C18.9367 8.4211 19.0001 8.70855 19 8.99948V17.9995C19 18.5299 18.7893 19.0386 18.4142 19.4137C18.0391 19.7888 17.5304 19.9995 17 19.9995H3C2.46957 19.9995 1.96086 19.7888 1.58579 19.4137C1.21071 19.0386 1 18.5299 1 17.9995V8.99948Z"
                                    stroke="black"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </NavLink>
                        <p className='m-0 p-0 activeP' >Home</p>
                    </div>
                    <div className='d-flex flex-column  align-items-center justify-content-center my-3'>
                        <NavLink
                            to="/home"
                            className={({ isActive }) =>
                                isActive ? "text-decoration-none active nav-color activeP d-flex flex-column  align-items-center justify-content-center " : " text-decoration-none inactiveP  d-flex flex-column align-items-center justify-content-center"
                            }
                            style={{ padding: "12px, 0px, 16px, 0px" }}
                        >
                            <svg
                                className='mb-1'
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 3.667C5 2.95967 5.28099 2.28131 5.78115 1.78115C6.28131 1.28099 6.95967 1 7.667 1H16.333C16.6832 1 17.03 1.06898 17.3536 1.20301C17.6772 1.33704 17.9712 1.53349 18.2189 1.78115C18.4665 2.0288 18.663 2.32281 18.797 2.64638C18.931 2.96996 19 3.31676 19 3.667V12.333C19 12.6832 18.931 13.03 18.797 13.3536C18.663 13.6772 18.4665 13.9712 18.2189 14.2189C17.9712 14.4665 17.6772 14.663 17.3536 14.797C17.03 14.931 16.6832 15 16.333 15H7.667C6.95967 15 6.28131 14.719 5.78115 14.2189C5.28099 13.7187 5 13.0403 5 12.333V3.667Z"
                                    stroke="black"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round" />
                                <path d="M2.012 5.26C1.705 5.43443 1.44965 5.68702 1.2719 5.99211C1.09415 6.2972 1.00034 6.64391 1 6.997V16.997C1 18.097 1.9 18.997 3 18.997H13C13.75 18.997 14.158 18.612 14.5 17.997M9 5H14M9 8H15M9 11H12"
                                    stroke="black"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round" />
                            </svg>
                        </NavLink>
                        <p className='m-0 p-0 inactiveP' >Policy Library</p>
                    </div>
                    <div className='d-flex flex-column  align-items-center justify-content-center my-3 d-none'>
                        <NavLink
                            to="/"
                            className={({ isActive }) =>
                                isActive ? "text-decoration-none active nav-color activeP d-flex flex-column  align-items-center justify-content-center " : " text-decoration-none inactiveP  d-flex flex-column align-items-center justify-content-center"
                            }
                            style={{ padding: "12px, 0px, 16px, 0px" }}
                        >
                            <svg
                                className='mb-1 '
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.0781 14.1811L15.1095 11.9547L15.0958 11.9483C14.8378 11.838 14.5564 11.7937 14.2771 11.8195C13.9977 11.8453 13.7292 11.9403 13.4958 12.096C13.4683 12.1141 13.4419 12.1338 13.4167 12.1551L10.8496 14.3435C9.22325 13.5536 7.54419 11.8872 6.75423 10.2819L8.94587 7.6758C8.96696 7.64944 8.987 7.62307 9.00599 7.59459C9.15831 7.36182 9.25073 7.09501 9.27502 6.81789C9.29931 6.54077 9.25472 6.26195 9.14521 6.00623V5.99358L6.91243 1.01651C6.76767 0.682449 6.51874 0.40417 6.20282 0.22321C5.88691 0.0422503 5.52093 -0.0316843 5.15954 0.0124428C3.73041 0.200501 2.4186 0.902355 1.46912 1.98692C0.519641 3.07148 -0.00257442 4.46459 9.54349e-06 5.90604C9.54349e-06 14.2803 6.81329 21.0935 15.1875 21.0935C16.629 21.0961 18.0221 20.5739 19.1066 19.6244C20.1912 18.6749 20.893 17.3631 21.0811 15.934C21.1253 15.5727 21.0515 15.2069 20.8708 14.891C20.69 14.575 20.412 14.3261 20.0781 14.1811ZM15.1875 19.406C11.6083 19.4021 8.17678 17.9786 5.64588 15.4477C3.11499 12.9168 1.69142 9.48526 1.68751 5.90604C1.68354 4.87612 2.05459 3.87998 2.73137 3.10363C3.40815 2.32729 4.34438 1.82383 5.36521 1.68729C5.36478 1.69149 5.36478 1.69573 5.36521 1.69994L7.58005 6.65697L5.40001 9.26627C5.37784 9.2917 5.35774 9.31885 5.33989 9.34748C5.18118 9.59103 5.08807 9.87144 5.06959 10.1616C5.05111 10.4517 5.10789 10.7416 5.23442 11.0033C6.18997 12.9577 8.15907 14.912 10.1345 15.8665C10.3981 15.9919 10.6898 16.0467 10.9809 16.0256C11.2721 16.0045 11.5528 15.9083 11.7956 15.7463C11.8228 15.7281 11.8488 15.7084 11.8737 15.6872L14.4376 13.4998L19.3947 15.7199H19.4063C19.2714 16.7422 18.7687 17.6803 17.9922 18.3587C17.2157 19.0372 16.2186 19.4095 15.1875 19.406Z"
                                    fill="black"
                                />
                            </svg>
                        </NavLink>
                        <p className='m-0 p-0 inactiveP' >Complaints</p>
                    </div>
                    <div className='d-flex flex-column  align-items-center justify-content-center my-3 d-none'>
                        <NavLink
                            to="/"
                            className={({ isActive }) =>
                                isActive ? "text-decoration-none active nav-color activeP d-flex flex-column  align-items-center justify-content-center " : " text-decoration-none inactiveP  d-flex flex-column align-items-center justify-content-center"
                            }
                            style={{ padding: "12px, 0px, 16px, 0px" }}
                        >
                            <svg
                                className='mb-1' width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.751 11.995C14.3474 11.9955 14.9192 12.2328 15.3407 12.6547C15.7622 13.0766 15.999 13.6486 15.999 14.245V14.82C15.999 15.714 15.679 16.579 15.099 17.258C13.529 19.091 11.142 19.996 7.996 19.996C4.85 19.996 2.464 19.091 0.898 17.256C0.318608 16.5774 0.000207249 15.7143 0 14.822V14.244C0.000265004 13.6476 0.237298 13.0757 0.659009 12.654C1.08072 12.2323 1.65261 11.9953 2.249 11.995H13.751ZM13.751 13.495H2.248C2.04909 13.495 1.85832 13.574 1.71767 13.7147C1.57702 13.8553 1.498 14.0461 1.498 14.245V14.822C1.498 15.357 1.69 15.875 2.038 16.282C3.291 17.751 5.258 18.496 7.995 18.496C10.734 18.496 12.701 17.751 13.958 16.283C14.3066 15.8753 14.4982 15.3564 14.498 14.82V14.244C14.4977 14.0456 14.4189 13.8554 14.2787 13.715C14.1385 13.5746 13.9494 13.4955 13.751 13.495ZM7.996 0C8.65261 -9.78424e-09 9.30279 0.129329 9.90942 0.380602C10.516 0.631876 11.0672 1.00017 11.5315 1.46447C11.9958 1.92876 12.3641 2.47995 12.6154 3.08658C12.8667 3.69321 12.996 4.34339 12.996 5C12.996 5.65661 12.8667 6.30679 12.6154 6.91342C12.3641 7.52004 11.9958 8.07124 11.5315 8.53553C11.0672 8.99983 10.516 9.36812 9.90942 9.6194C9.30279 9.87067 8.65261 10 7.996 10C6.66992 10 5.39815 9.47322 4.46047 8.53553C3.52278 7.59785 2.996 6.32608 2.996 5C2.996 3.67392 3.52278 2.40215 4.46047 1.46447C5.39815 0.526784 6.66992 0 7.996 0ZM7.996 1.5C7.53637 1.5 7.08125 1.59053 6.65661 1.76642C6.23197 1.94231 5.84613 2.20012 5.52113 2.52513C5.19612 2.85013 4.93831 3.23597 4.76242 3.66061C4.58653 4.08525 4.496 4.54037 4.496 5C4.496 5.45963 4.58653 5.91475 4.76242 6.33939C4.93831 6.76403 5.19612 7.14987 5.52113 7.47487C5.84613 7.79988 6.23197 8.05769 6.65661 8.23358C7.08125 8.40947 7.53637 8.5 7.996 8.5C8.92426 8.5 9.8145 8.13125 10.4709 7.47487C11.1273 6.8185 11.496 5.92826 11.496 5C11.496 4.07174 11.1273 3.1815 10.4709 2.52513C9.8145 1.86875 8.92426 1.5 7.996 1.5Z" fill="black" />
                            </svg>
                        </NavLink>
                        
                        <p className='m-0 p-0 inactiveP' >Vendors</p>
                    </div>
                </div>
                <div className='position-absolute bottom-0 start-50 translate-middle holdCursor' onClick={handleLogOut}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.85 17.1C6.7 16.45 7.65 15.9375 8.7 15.5625C9.75 15.1875 10.85 15 12 15C13.15 15 14.25 15.1875 15.3 15.5625C16.35 15.9375 17.3 16.45 18.15 17.1C18.7333 16.4167 19.1875 15.6417 19.5125 14.775C19.8375 13.9083 20 12.9833 20 12C20 9.78333 19.2208 7.89583 17.6625 6.3375C16.1042 4.77917 14.2167 4 12 4C9.78333 4 7.89583 4.77917 6.3375 6.3375C4.77917 7.89583 4 9.78333 4 12C4 12.9833 4.1625 13.9083 4.4875 14.775C4.8125 15.6417 5.26667 16.4167 5.85 17.1ZM12 13C11.0167 13 10.1875 12.6625 9.5125 11.9875C8.8375 11.3125 8.5 10.4833 8.5 9.5C8.5 8.51667 8.8375 7.6875 9.5125 7.0125C10.1875 6.3375 11.0167 6 12 6C12.9833 6 13.8125 6.3375 14.4875 7.0125C15.1625 7.6875 15.5 8.51667 15.5 9.5C15.5 10.4833 15.1625 11.3125 14.4875 11.9875C13.8125 12.6625 12.9833 13 12 13ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z" fill="#49454F" />
                    </svg>
                    <p className='m-0 p-0'>Logout</p>
                </div>
            </nav>
        </>
    )
}
