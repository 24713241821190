import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; 
export default function LoadingMainCard({ cards }) {
  return (
    <div className='row m-auto'>
      <p className='mb-4' style={{ fontWeight: "200", fontSize: "24px" }}>Latest Documents</p>
      {Array(cards).fill(0).map((_, index) => (
        <div key={index} className="col-lg-3 col-md-6 col-sm-12 mb-4">
          <div className='px-3 h-100 d-flex flex-column documents' style={{ border: '1px solid #D8D6D6', borderRadius: "25px" }}>
            <div className='d-flex mt-3'>
              <div>
                <p className={`policeName`}><Skeleton baseColor="#EBE6F0" highlightColor="#f5f5f5" width={250} height={10}/></p>
                <p><Skeleton baseColor="#EBE6F0" highlightColor="#f5f5f5" width={250} height={10}/></p>
                <p className=''><Skeleton baseColor="#EBE6F0" highlightColor="#f5f5f5" width={250} height={10}/></p>
                <p className=''><Skeleton baseColor="#EBE6F0" highlightColor="#f5f5f5" width={250} height={10}/></p>
              </div>
            </div>
          </div>
        </div>
      ))}

    </div>
  );
}

