import React, { useEffect, useState } from 'react';
import PDF from './PDF/PDF';
// import Table from './Table/Table';
import { useLocation, useParams } from 'react-router-dom';
import TestNav from '../NavBar/NavBar';
import axios from 'axios';


export default function PoliciesDetails() {
    const { name,id } = useParams();
    const [details, setDetails] = useState([]);
    // const location = useLocation();
    // const { policy } = location.state || {};
    
    const getData = async () => {
        try {
            const { data } =  await axios.get(`https://pirm-portal.uc.r.appspot.com/polices/${id}`);
            setDetails(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        getData();
    }, );
    return (
        <>
            <div className=' overflow-hidden d-flex' style={{ backgroundColor: '#F9F3FA', paddingTop: "0px" }}>
                <TestNav />
                <div className="container-fluid pt-3 mb-3">
                    <div className=' mb-3 rounded-2 d-flex  documents' style={{ border: '1px solid #D8D6D6', borderRadius: "25px" }}>
                        <div className='pb-1'>
                            <h5 className='m-0 py-2 ms-3' style={{ fontWeight: "600" }}>
                                {name}
                            </h5>
                        </div>
                    </div>
                    <div className="row">
                        {/* <div className="col-md-7">
                            <Table  policy={policy} />
                        </div> */}
                        <div className="col-md-12 vh-100 ">
                            <PDF  name={name} details={details} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
