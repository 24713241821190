import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import { Security, LoginCallback } from "@okta/okta-react";
import LayOuts from "./components/LayOuts/LayOuts";
import Home from "./components/Home/Home";
import PolicesDetails from "./components/PolicesDetails/PolicesDetails";
import { useEffect, useState } from "react";
import Protected from "./components/Protected/Protected";
import PoliciesProvider from "./Context/ploicesContext";

import OktaAuth, { toRelativeUrl } from "@okta/okta-auth-js";
import { oktaConfig } from "./lib/oktaConfig";
import Login from "./components/Login/Login";
import AppProvider from "./Context/AppContext";
import { Helmet, HelmetProvider } from "react-helmet-async";
const CALLBACK_PATH = "/login/callback"; // Ensure this matches your redirect URI

function App() {
  const oktaAuth = new OktaAuth(oktaConfig);
  const navigate = useNavigate();

  const handleLogin = (userData) => {
    localStorage.setItem("userData", JSON.stringify(userData));
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin), {
      replace: true,
    });
  };

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Compliance Portal</title> 
      </Helmet>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <PoliciesProvider>
          <AppProvider>
            <Routes>
              <Route element={<LayOuts />}>
                <Route index="/" element={<Login onLogin={handleLogin} />} />
                <Route
                  path="/home"
                  element={
                    <Protected>
                      <Home />
                    </Protected>
                  }
                />
                <Route path={CALLBACK_PATH} element={<LoginCallback />} />
                <Route
                  path="/details/:name/:id"
                  element={
                    <Protected>
                      <PolicesDetails />
                    </Protected>
                  }
                />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Route>
            </Routes>
          </AppProvider>
        </PoliciesProvider>
      </Security>
    </HelmetProvider>
  );
}

export default App;
