import React from 'react';
import Skeleton from 'react-loading-skeleton';
import css from '../PolicesDetails/style.module.css'; // Adjust the path as necessary

export default function SkeletonPDF() {
    return (
        <div className={`${css.size}`}>
            <Skeleton height="100%" width="100%" />
        </div>
    );
}
