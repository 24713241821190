import axios from "axios";
import { createContext, useEffect, useState } from "react";

export const PoliciesContext = createContext(0);

export default function PoliciesProvider({ children }) {
  const [policies, setPolicies] = useState([]);



  useEffect(() => {
    getPolicies();
  }, []);

  const getPolicies = async () => {
    try {
      const { data } = await axios.get(" https://pirm-portal.uc.r.appspot.com/polices");   
      setPolicies(data); 
    } catch (error) {
      console.error("Error fetching policies:", error.message);
    }
  };

  return (
    <PoliciesContext.Provider value={{ policies }}>
      {children}
    </PoliciesContext.Provider>
  );
}
